import { Typography } from "antd";
import PageCard, { SimpleCard } from "component/PageCard";
import { Link } from "react-router-dom";

const ComingSoonPage = ({ children }: React.PropsWithChildren) => {
  return (
    <PageCard>
      <SimpleCard
        title="Coming Soon"
        subtitle="The app is still in progress!!!"
        src={""}
      >
        <Typography.Paragraph>We're constantly making the app better. We recently took it down to upgrade it</Typography.Paragraph>
        <Typography.Paragraph>Here's what you can do:</Typography.Paragraph>
        <ul>
          <li>
            <Link to="/home">Give us some feedback</Link>.
          </li>
          <li>
            Follow our <a href="https://instagram.com/jigsaur.app">instagram page</a>.
          </li>
        </ul>
      </SimpleCard>
    </PageCard>
  );
};
export default ComingSoonPage;
